import React,{useEffect} from 'react';
import '../../css/email.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Emailholder() {

  useEffect(() => {
    AOS.init({duration:2000})
  }, [])
    

  return (
    
    <div className='email-hold'> 
      <div className="email-div">
        <a href="mailto:mutegisamuel0@gmail.com?subject=Hello" target="_blank" rel="noreferrer"
           className="email">mutegisamuel0@gmail.com</a>
      </div>
      <div className="line">
        <hr className='soc-line'/>
      </div>
    </div>
  )
    
}
