import React, { useState, useEffect } from 'react';
import '../../css/navigation.css';
import { Link } from 'react-scroll';
import { RiMenu4Fill } from "react-icons/ri";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Navigation() {

useEffect(() => {
  AOS.init({duration:2000})
}, [])
  

  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const logo = document.querySelectorAll('#logo path');
  for (let i = 0; i < logo.length; i++) {
    console.log(`letter ${i} is ${logo[1].getTotalLength()}`)
  }

  
    const openResume = () => {
      window.open('resume.pdf', '_blank');
    };

  return (
    <>
      <div className='navigation-main'>
        <nav>
          <div className="name-logo">
          <Link id='link'  to="hero" spy={true} smooth={true} offset={-200} duration={700} >
          <span id='name'>
              <svg id='logo' width="150" height="50" viewBox="0 0 102 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.6983 1.75H23.07V23.5H18.925V11.75L17.9736 11.5349L13.4894 20.945H10.5856L6.10138 11.5349L5.15001 11.75V23.5H1.005V1.75H5.37599L11.5728 14.1436L12.0188 15.0356L12.4667 14.1446L18.6983 1.75Z" stroke="#5CF64A" />
                <path d="M38.2677 22.705V21.5222L37.4193 22.3464C36.3179 23.4163 34.852 23.78 33.4977 23.78C32.1751 23.78 30.7436 23.4316 29.652 22.5258C28.5774 21.6343 27.7677 20.1495 27.7677 17.735V8.4H31.7377V16.965C31.7377 18.4659 32.2858 19.4045 33.0265 19.9491C33.739 20.473 34.5586 20.58 35.0377 20.58C35.5332 20.58 36.3403 20.4734 37.0342 19.9437C37.7536 19.3945 38.2677 18.4555 38.2677 16.965V8.4H42.2727V23.5H38.2677V22.705Z" stroke="#5CF64A" />
                <path d="M52.4275 11.145H51.9275V11.645V18.26C51.9275 19.3422 52.2645 20.0291 52.8942 20.3775C53.3988 20.6567 54.0127 20.6529 54.4226 20.6504C54.4593 20.6502 54.4943 20.65 54.5275 20.65H55.0425V23.5H52.4275C50.7759 23.5 49.6752 23.0754 48.9829 22.3683C48.2887 21.6593 47.9225 20.5819 47.9225 19.1V11.645V11.145H47.4225H45.8925V8.4H47.4225H47.9225V7.9V3.43H51.9275V7.9V8.4H52.4275H55.2175V11.145H52.4275Z" stroke="#5CF64A" />
                <path d="M71.843 21.2277C70.5094 22.8225 68.3524 23.85 65.7603 23.85C63.4017 23.85 61.4793 22.9975 60.1454 21.6026C58.8091 20.2051 58.0353 18.2335 58.0353 15.95C58.0353 13.6621 58.7857 11.6987 60.0874 10.3115C61.3859 8.92779 63.2629 8.085 65.5853 8.085C67.869 8.085 69.6724 8.92486 70.9094 10.3033C72.1517 11.6877 72.8553 13.6529 72.8553 15.95V16.78H62.3653H61.7368L61.8781 17.3924C62.3482 19.4295 63.8726 20.615 65.7603 20.615C67.5863 20.615 68.642 19.9061 69.2328 19.2902L71.843 21.2277ZM61.9534 14.0317L61.7782 14.665H62.4353H68.6653H69.3199L69.1477 14.0334C68.921 13.2023 68.533 12.4451 67.9385 11.8916C67.3349 11.3296 66.5468 11.005 65.5853 11.005C64.6681 11.005 63.8758 11.297 63.2495 11.8405C62.6289 12.379 62.2005 13.1384 61.9534 14.0317Z" stroke="#5CF64A" />
                <path d="M87.9097 9.265V8.4H91.8797V22.88C91.8797 25.0819 91.1727 26.9573 89.832 28.2818C88.4921 29.6056 86.4675 30.43 83.7347 30.43C81.1452 30.43 78.955 29.4638 77.5592 28.0391L80.0748 25.9292C81.3498 27.0387 82.7695 27.23 83.7347 27.23C84.3063 27.23 85.3353 27.086 86.237 26.4633C87.1695 25.8192 87.9097 24.6999 87.9097 22.88V22.635V21.4279L87.0561 22.2814C86.1384 23.1992 84.8671 23.78 83.2097 23.78C79.1928 23.78 76.1847 20.5183 76.1847 15.95C76.1847 11.3846 79.1902 8.155 83.2097 8.155C84.8751 8.155 86.1437 8.70611 87.0561 9.61855L87.9097 10.4721V9.265ZM80.0147 15.95C80.0147 17.1048 80.3814 18.142 81.074 18.8958C81.7708 19.654 82.7656 20.09 83.9447 20.09C85.1232 20.09 86.1254 19.6546 86.8304 18.8985C87.5321 18.1459 87.9097 17.1083 87.9097 15.95C87.9097 14.7912 87.5318 13.7611 86.8281 13.0165C86.1216 12.2691 85.1196 11.845 83.9447 11.845C82.7692 11.845 81.7746 12.2696 81.0763 13.0192C80.3817 13.765 80.0147 14.7948 80.0147 15.95Z" stroke="#5CF64A" />
                <path d="M100.961 3.14C100.961 4.54156 99.8587 5.37 98.7656 5.37C97.7122 5.37 96.6056 4.54552 96.6056 3.14C96.6056 1.79948 97.6788 1.05 98.7656 1.05C99.8921 1.05 100.961 1.80326 100.961 3.14ZM100.82 8.4L100.787 23.5H96.7806V8.4H100.82Z" stroke="#5CF64A" />
              </svg>
            </span>
          </Link>
          </div>
          <div className="navigation-section">
            <ul className="nav-section">
              <Link data-aos="fade-down"  data-aos-duration="3000" id='link' to="about" spy={true} smooth={true} offset={-150} duration={700} ><span className="num">01.</span>About</Link>
            </ul>
            <ul className="nav-section">
            <Link data-aos="fade-down"  data-aos-duration="4000" id='link' to="exp-sec" spy={true} smooth={true} offset={0} duration={700} ><span className="num">02.</span>Experience</Link> 
            </ul>
            <ul className="nav-section">
            <Link data-aos="fade-down"  data-aos-duration="2000" id='link' to="work" spy={true} smooth={true} offset={-20} duration={700} ><span className="num">03.</span>Work</Link>
            </ul>
            <ul className="nav-section">
            <Link data-aos="fade-down"  data-aos-duration="1000" id='link' to="contact" spy={true} smooth={true} offset={-70} duration={700} ><span className="num">04.</span>Contact</Link>
            </ul>
            <button data-aos="fade-in" data-aos-duration="10000" className='resume-btn' onClick={openResume}>Resume</button>
          </div>
        </nav>
      </div>

      <div className='mobile-navigation-main'>
        <nav className='mobile-nav'>
          <div className="mobile-name-logo">
            <span id='mobile-name'>
              <svg id='logo' width="150" height="50" viewBox="0 0 102 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.6983 1.75H23.07V23.5H18.925V11.75L17.9736 11.5349L13.4894 20.945H10.5856L6.10138 11.5349L5.15001 11.75V23.5H1.005V1.75H5.37599L11.5728 14.1436L12.0188 15.0356L12.4667 14.1446L18.6983 1.75Z" stroke="#5CF64A" />
                <path d="M38.2677 22.705V21.5222L37.4193 22.3464C36.3179 23.4163 34.852 23.78 33.4977 23.78C32.1751 23.78 30.7436 23.4316 29.652 22.5258C28.5774 21.6343 27.7677 20.1495 27.7677 17.735V8.4H31.7377V16.965C31.7377 18.4659 32.2858 19.4045 33.0265 19.9491C33.739 20.473 34.5586 20.58 35.0377 20.58C35.5332 20.58 36.3403 20.4734 37.0342 19.9437C37.7536 19.3945 38.2677 18.4555 38.2677 16.965V8.4H42.2727V23.5H38.2677V22.705Z" stroke="#5CF64A" />
                <path d="M52.4275 11.145H51.9275V11.645V18.26C51.9275 19.3422 52.2645 20.0291 52.8942 20.3775C53.3988 20.6567 54.0127 20.6529 54.4226 20.6504C54.4593 20.6502 54.4943 20.65 54.5275 20.65H55.0425V23.5H52.4275C50.7759 23.5 49.6752 23.0754 48.9829 22.3683C48.2887 21.6593 47.9225 20.5819 47.9225 19.1V11.645V11.145H47.4225H45.8925V8.4H47.4225H47.9225V7.9V3.43H51.9275V7.9V8.4H52.4275H55.2175V11.145H52.4275Z" stroke="#5CF64A" />
                <path d="M71.843 21.2277C70.5094 22.8225 68.3524 23.85 65.7603 23.85C63.4017 23.85 61.4793 22.9975 60.1454 21.6026C58.8091 20.2051 58.0353 18.2335 58.0353 15.95C58.0353 13.6621 58.7857 11.6987 60.0874 10.3115C61.3859 8.92779 63.2629 8.085 65.5853 8.085C67.869 8.085 69.6724 8.92486 70.9094 10.3033C72.1517 11.6877 72.8553 13.6529 72.8553 15.95V16.78H62.3653H61.7368L61.8781 17.3924C62.3482 19.4295 63.8726 20.615 65.7603 20.615C67.5863 20.615 68.642 19.9061 69.2328 19.2902L71.843 21.2277ZM61.9534 14.0317L61.7782 14.665H62.4353H68.6653H69.3199L69.1477 14.0334C68.921 13.2023 68.533 12.4451 67.9385 11.8916C67.3349 11.3296 66.5468 11.005 65.5853 11.005C64.6681 11.005 63.8758 11.297 63.2495 11.8405C62.6289 12.379 62.2005 13.1384 61.9534 14.0317Z" stroke="#5CF64A" />
                <path d="M87.9097 9.265V8.4H91.8797V22.88C91.8797 25.0819 91.1727 26.9573 89.832 28.2818C88.4921 29.6056 86.4675 30.43 83.7347 30.43C81.1452 30.43 78.955 29.4638 77.5592 28.0391L80.0748 25.9292C81.3498 27.0387 82.7695 27.23 83.7347 27.23C84.3063 27.23 85.3353 27.086 86.237 26.4633C87.1695 25.8192 87.9097 24.6999 87.9097 22.88V22.635V21.4279L87.0561 22.2814C86.1384 23.1992 84.8671 23.78 83.2097 23.78C79.1928 23.78 76.1847 20.5183 76.1847 15.95C76.1847 11.3846 79.1902 8.155 83.2097 8.155C84.8751 8.155 86.1437 8.70611 87.0561 9.61855L87.9097 10.4721V9.265ZM80.0147 15.95C80.0147 17.1048 80.3814 18.142 81.074 18.8958C81.7708 19.654 82.7656 20.09 83.9447 20.09C85.1232 20.09 86.1254 19.6546 86.8304 18.8985C87.5321 18.1459 87.9097 17.1083 87.9097 15.95C87.9097 14.7912 87.5318 13.7611 86.8281 13.0165C86.1216 12.2691 85.1196 11.845 83.9447 11.845C82.7692 11.845 81.7746 12.2696 81.0763 13.0192C80.3817 13.765 80.0147 14.7948 80.0147 15.95Z" stroke="#5CF64A" />
                <path d="M100.961 3.14C100.961 4.54156 99.8587 5.37 98.7656 5.37C97.7122 5.37 96.6056 4.54552 96.6056 3.14C96.6056 1.79948 97.6788 1.05 98.7656 1.05C99.8921 1.05 100.961 1.80326 100.961 3.14ZM100.82 8.4L100.787 23.5H96.7806V8.4H100.82Z" stroke="#5CF64A" />
              </svg>
            </span>
            <span className="icn" onClick={toggleNav}><RiMenu4Fill /></span>
          </div>
          <div className={`mobile-navigation-section ${isOpen ? 'open' : 'close'}`}>
          <ul className="nav-section">
              <Link data-aos="fade-in"  data-aos-duration="3000" id='link' to="about" spy={true} smooth={true} offset={-150} duration={700} ><span className="num">01.</span>About</Link>
            </ul>
            <ul className="nav-section">
            <Link data-aos="fade-in"  data-aos-duration="3000" id='link' to="exp-sec" spy={true} smooth={true} offset={0} duration={700} ><span className="num">01.</span>Experience</Link>
              
            </ul>
            <ul className="nav-section">
            <Link data-aos="fade-in"  data-aos-duration="3000" id='link' to="work" spy={true} smooth={true} offset={-20} duration={700} ><span className="num">01.</span>Work</Link>
            </ul>
            <ul className="nav-section">
            <Link data-aos="fade-in"  data-aos-duration="3000" id='link' to="contact" spy={true} smooth={true} offset={-70} duration={700} ><span className="num">01.</span>Contact</Link>
            </ul>
            <button data-aos="fade-in"  data-aos-duration="3000" className='resume-btn' onClick={openResume}>Resume</button>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Navigation;