import React, {useRef, useState, useEffect } from 'react';
import '../../css/mainnomp.css';
import { Link } from 'react-scroll';
import { RiTerminalFill } from "react-icons/ri";
import { RiGithubFill, RiLinkedinBoxFill, RiBehanceFill, RiInstagramFill, RiExternalLinkLine } from "react-icons/ri";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Maincomp() {

  const [activeIndex, setActiveIndex] = useState(0);

  const menuItems = [
    'Cchub Nigeria',
    'Tunza money',
    'eLimu Kenya',
    'Infinicom Kenya',
    'Techeck Investments',
  ];

  const contentItems = [
    {
      title: 'Full-Stack engineer @ eLimu World',
      dates: 'November 2020 - present',
      experiences: [
        'Adopted engineering best practices and delivered highest quality scalable solutions.',
        'Designed and implemented scalable application programming interfaces.',
        'Paired with fellow engineers to brainstorm ideas, debug problems, and review code.',
        'Worked with customers to define requirements, troubleshoot issues, and architect and implement new engineering applications to meet needs.',
      ],
    },
    {
      title: 'Product Designer @ Tunza Money',
      dates: 'March 2020 - November 2020',
      experiences: [
        'Suggested enhancements to product design that would improve user experience.',
        'Brainstormed with peers and other members of the team to determine enhancements and product features.',
        'Converted project specifications into drawings and other materials that would be used to create the product.',
        'Documented each step in the product\'s design process for use in manuals.',
      ],
    },
    {
      title: 'Backend engineer (Python Django) @ eLimu Kenya',
      dates: 'July 2019 - March 2020',
      experiences: [
        'Developed and maintained core product services, libraries, and frameworks.',
        'Improved performance and reliability of databases, web services, and other integrations.',
        'Collaborated with other teams on security, automation, and internal tools.',
        'Evaluated and developed new tools and technologies to help achieve company-level goals.',
      ],
    },
    {
      title: 'Android Developer (Java) @ Infinicom Kenya',
      dates: 'June 2018 - July 2019',
      experiences: [
        'Devised documentation for each app, detailing operation aspects, functions, capabilities, and features.',
        'Designed user interfaces that engaged multiple senses and produced immersive experiences.',
        'Devised techniques for better modularity, testability, and maintainability of front-end applications.',
        'Created custom mobile applications using native technologies.',
      ],
    },
    {
      title: 'Computer Technician @ Techeck Investments',
      dates: 'January 2017 - March 2018',
      experiences: [
        'Responded to support requests from end-users and patiently walked individuals through basic troubleshooting tasks.',
        'Tested and installed motherboards, processors, and graphics cards on desktops and laptops for corporate staff.',
        'Refurbished PC systems and peripherals such as monitors, networking equipment, printers, and more.',
        'Linked computers to the network and peripheral equipment.',
      ],
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(index);
  };

  /* -- Glow effect -- */
  const blobRef = useRef(null);

  function handlePointerMove(event) {
    const { clientX, clientY } = event;

    blobRef.current.animate({
      left: `${clientX}px`,
      top: `${clientY}px`
    }
      ,
      { duration: 7000, fill: "forwards" }
    );
  }

  useEffect(() => {
    AOS.init({duration:2000})
  }, [])

  return (
    <>
      <div id="blob" data-aos="fade-in" data-aos-duration="4000" ref={blobRef} ></div>
      <div className="maiin-comp" onPointerMove={handlePointerMove}>

        <div className="hero" data-aos="fade-in" data-aos-duration="3000" id='hero'>
          <p className='my-name' data-aos="fade-right" data-aos-duration="2000">Hi, my name is</p>
          <h1 className='light-shade'>Mutegi Samuel <br /> <span className='dark-shade'>I build things for the web.</span></h1>
          <p className='description'>As a software engineer in the edtech industry, I specialize in constructing and, at times, designing exceptional digital learning experiences. Currently, my emphasis is on creating accessible edtech products that prioritize the needs of the student at <br></br> <a id='green' rel="noopener noreferrer" target='_blank' href="http://e-limu.org">eLimu World.</a> </p>
          <Link id='link' to="work" spy={true} smooth={true} offset={-20} duration={700} ><button className="cta">find out more about my work</button></Link>
        </div>

        <div className="about-sec" id='about'>
          <div className="about">
            <p className="no">01.</p><h2 className='ab-txt'>About Me</h2><hr className='ab-line' />
          </div>

          <div className="about-wrapper" data-aos="fade-in" data-aos-duration="4000">
            <div className="wrap-text" data-aos="fade-up" data-aos-duration="3000">
              <p className="about-w-txt">
                Hey there! I'm <span className="green-pop">Mutegi</span>, a tech enthusiast and a passionate problem solver.
                My journey into the world of coding began with my adventures as a computer
                and mobile technician, tinkering with devices and unraveling their mysteries.
                I was immediately hooked! Little did I know that this fascination would lead
                me to become a full stack developer, weaving magic across various domains.
                <br></br><br></br>
                After mastering the art of <span className="green-pop">Android development</span>, I ventured into the realm of
                <span className="green-pop"> Python Django backend development</span>, where I embraced the power of data manipulation
                and <span className="green-pop">server-side sorcery</span>. As my skills evolved, so did my love for all things JavaScript.
                For the past three years, I've been fully immersed in the captivating world of <span className="green-pop">full-stack
                  JavaScript development</span>, crafting delightful user experiences and pushing the boundaries of
                what's possible.
                <br></br><br></br>
                From <span className="green-pop">startups to established corporations</span>, I've had the privilege of contributing my skills
                and expertise to diverse projects. Now, I thrive on the thrill of building accessible and
                inclusive digital products that leave a lasting impact. With a creative mind and an <span className="green-pop">arsenal
                  of cutting-edge technologies</span>, I'm here to transform your ideas into reality.
                <br></br><br></br>
                So, whether it's sculpting elegant front-end interfaces, engineering robust back-end systems,
                or <span className="green-pop">orchestrating the harmonious symphony of a full stack</span>, I'm ready to embark on the next adventure
                and create something extraordinary together.
                <br></br><br></br>
                Let's make magic happen!
                <br></br><br></br>
                Here are a few technologies I've been conjuring lately:
              </p>
              <div className="skillz">
                <div className="line1">
                  <div id='bop'><RiTerminalFill className='log1' />React</div>
                  <div id='bop'><RiTerminalFill className='log1' />JavaScript (ES6+)</div>
                </div>
                <div className="line1">
                  <div id='bop'><RiTerminalFill className='log1' />TypeScript</div>
                  <div id='bop'><RiTerminalFill className='log1' />Node.js</div>
                </div>
                <div className="line1">
                  <div id='bop'><RiTerminalFill className='log1' />WordPress</div>
                  <div id='bop'><RiTerminalFill className='log1' />NestJS</div>
                </div>
              </div>
            </div>
            <div className="wrap-img">
              <div className="mee">
                <img className='me' src="https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Fportfolio%20img-min.png?alt=media&token=5e5aa29e-ffb2-415c-96fb-3902b064a296&_gl=1*oz9l0k*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjYxNDAuMC4wLjA." alt="" />
              </div>

            </div>
          </div>

        </div>

        <div className="exp-sec" id='exp-sec'>
          <div className="about">
            <p className="no">02.</p><h2 className='ab-txt'>Experience</h2><hr className='ab-line' />
          </div>

          <div className="exp-wrapper" data-aos="fade-up" data-aos-duration="3000">
            <div className="accordion-container">
              <div className="scroll">
                <div className="menu-container">
                  {menuItems.map((item, index) => (
                    <div
                      key={index}
                      className={`menu-item ${activeIndex === index ? 'active' : ''}`}
                      onClick={() => toggleAccordion(index)}
                    >
                      <h2>{item}</h2>
                    </div>
                  ))}
                </div>
              </div>

              <div className="content-container">
                {contentItems.map((item, index) => (
                  <div
                    key={index}
                    className={`content-item ${activeIndex === index ? 'active' : ''}`}
                  >
                    <h3 className="acc-title">{item.title}</h3>
                    <p className="dates">{item.dates}</p>

                    {item.experiences.map((experience, expIndex) => (
                      <div className="ep" key={expIndex}>
                        <span className="greeny">
                          <RiTerminalFill className="log" />
                        </span>
                        <p className='experience-txt'>{experience}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              
            </div>
          </div>
        </div>


        <div className="expience-sec" id='work'>
          <div className="about">
            <p className="no">03.</p><h2 className='ab-txt'>Some things I've built</h2><hr className='ab-line' />
          </div>

          <div className="exp-wrapper" data-aos="fade-up" data-aos-duration="3000">
            <div className="left-side">

            </div>
            <div className="right-side">

              <div className="feature-txt">
                <p className="feat-txt">Featured Project</p>
                <p className="project-title">eLimu World</p>
              </div>
              <div className="proj-img">

              </div>
              <div className="project-description">eLimu World is an online learning platform that provides
                revision content with explanations, memorable songs and engaging animations for students
                in Africa. The platform is designed to improve student learning outcomes by providing
                well-researched and appropriate textbooks focused on improving student learning outcomes.
              </div>
              <div className="project-footer">
                <div className="project-spans">
                  <span className="stack">TypeScript</span><span className="stack">NestJs</span><span className="stack">AWS</span><a className='ace' rel="noopener noreferrer" target='_blank' href="http://e-limu.org"><RiExternalLinkLine /></a>
                </div>
              </div>
            </div>

          </div>

          <div className="exp-wrapper" data-aos="fade-up" data-aos-duration="3000">
            <div className="ndleft-side">

            </div>
            <div className="ndright-side">

              <div className="feature-txt">
                <p className="feat-txt">Featured Project</p>
                <p className="project-title">eLimu Prep</p>
              </div>
              <div className="ndproj-img">

              </div>
              <div className="project-description">eLimu Prep is an online learning platform that provides
                past KCPE questions and answers with explanations for each solution and revision materials.
                The platform features a scoring system that helps track your progress as well as providing
                explanations & solutions to questions answered wrongly
              </div>
              <div className="project-footer">
                <div className="project-spans">
                  <span className="stack">JavaScript</span><span className="stack">ReactJs</span><span className="stack">AWS</span><a className='ace' rel="noopener noreferrer" target='_blank' href="http://exams.e-limu.org"><RiExternalLinkLine /></a>
                </div>
              </div>
            </div>

          </div>

          <div className="exp-wrapper" data-aos="fade-up" data-aos-duration="3000">
            <div className="rdleft-side">

            </div>
            <div className="rdright-side">

              <div className="feature-txt">
                <p className="feat-txt">Featured Project</p>
                <p className="project-title">eLimu Learn</p>
              </div>
              <div className="rdproj-img">

              </div>
              <div className="project-description"> is an online learning platform that provides
                revision content with explanations. The platform is designed to improve student
                learning outcomes by providing well-researched and appropriate textbooks focused
                on improving student learning outcomes.
              </div>
              <div className="project-footer">
                <div className="project-spans">
                  <span className="stack">Python</span><span className="stack">Django</span><span className="stack">AWS</span><span className="stack-icn"><a className='ace' rel="noopener noreferrer" target='_blank' href="http://learn.e-limu.org"><RiExternalLinkLine /></a></span>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className="contact-sec" id='contact' data-aos="fade-in" data-aos-duration="3000">
          <div className="cont">
            <p className="my-name">what's next?</p>
            <h2 className='cont-txt'>Get In Touch</h2>
            <p className="cont-description">Although I’m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!</p>
            <a href="mailto:mutegisamuel0@gmail.com?subject=Hello" target="_blank" rel="noreferrer" className="say-hello">Say Hello</a>
          </div>
          <div className="mobile-soc-icons">
            <a className="micn" href="https://www.behance.net/mutegisamuel" target="_blank" rel="noreferrer" ><span ><RiBehanceFill /></span></a>
            <a className="micn" href="https://github.com/Mutegisamuel" target="_blank" rel="noreferrer" ><span ><RiGithubFill /></span></a>
            <a className="micn" href="https://www.linkedin.com/in/mutegisamuel-9a3352157/" target="_blank" rel="noreferrer" ><span ><RiLinkedinBoxFill /></span></a>
            <a className="micn" href="https://www.instagram.com/s_mutegi/" target="_blank" rel="noreferrer" ><span ><RiInstagramFill /></span></a>
          </div>
        </div>



      </div>
    </>
  )
}
