import React,{useEffect} from 'react';
import '../../css/socials.css';
import { RiGithubFill, RiLinkedinBoxFill, RiBehanceFill, RiInstagramFill  } from "react-icons/ri";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function SocialComp() {

  useEffect(() => {
    AOS.init({duration:2000})
  }, [])
    

  return (
    <div className='main-soc' data-aos="fade-up"  data-aos-duration="3000">
      <div className="soc-icons">
        <a className="icn" href="https://www.behance.net/mutegisamuel" target="_blank" rel="noreferrer"><span ><RiBehanceFill/></span></a>
        <a className="icn" href="https://github.com/Mutegisamuel" target="_blank" rel="noreferrer"><span ><RiGithubFill/></span></a>
        <a className="icn" href="https://www.linkedin.com/in/mutegisamuel-9a3352157/" target="_blank" rel="noreferrer"><span ><RiLinkedinBoxFill/></span></a>
        <a className="icn" href="https://www.instagram.com/s_mutegi/" target="_blank" rel="noreferrer"><span ><RiInstagramFill/></span></a>
      </div>
      <div className="line">
        <hr className='soc-line' /> 
      </div>
    </div>
  )
}
