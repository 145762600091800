import './App.css';
import React, { useState, useEffect } from 'react';
import { useLottie }from 'lottie-react';
import PortLoading from '../src/animations/port-loading.json';
import Naviation from '../src/components/nav/navigation';
import Maincomp from './components/main-comp/maincomp';
import Emailholder from './components/email-place/emailholder';
import SocialComp from './components/social-logos/social-comp';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  const options = {
    animationData: PortLoading,
    loop: true,
    height: 100,
    width: 100
  };

  const { View } = useLottie(options);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); 
  }, []);


  return (
    <>
    {isLoading ? 
    <div className='lottie-view'>
      <div className="lot-img">
      <>{View}</>
      </div>
    </div> 
    : <>
    <Naviation/>

   <div className="app">
    <SocialComp/>
    <Maincomp/>
    <Emailholder/>

   </div>
    </> }
    </>
  );
}

export default App;
